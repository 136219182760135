import React from 'react';

function TermsOfServices() {
	return (
		<main
			className='home-container container tp'
			style={{ maxWidth: '1000px', paddingTop: '100px' }}>
			<h1 className='c21'>
				<span className='c9'>
					<br />
					Terms and Conditions
				</span>
			</h1>
			<p className='c4'>
				<b className='c0'>Last Updated: November 21, 2024</b>
			</p>

			<p className='c4'>
				<span className='c0'>
					Welcome to Memoryze! Memoryze, Inc. ("Memoryze," "we," or "our")
					provides a platform that enables users ("Users," "you," or "your") to
					access and subscribe to audio-based educational courses ("Courses")
					created by educators. These Terms and Conditions ("Terms") govern your
					access to and use of Memoryze's website at{' '}
					<a
						href='https://memoryze.co'
						target='_blank'
						rel='noopener noreferrer'>
						https://memoryze.co
					</a>
					, its subdomains, mobile applications, and any related platforms or
					services (collectively, the "Site").
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Eligibility to Use the Site</span>
			</h2>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						You must be at least 18 years old to register for an account or use
						the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						If you are between 13 and 18 years old, you may use the Site only
						with the consent and supervision of a parent or guardian, and by
						doing so, you confirm that you have obtained such permission.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Users under the age of 13 are strictly prohibited from accessing or
						using the Site.
					</span>
				</li>
			</ul>
			<h2 className='c2'>
				<span className='c9'>Acceptance of Terms</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					By accessing or using the Site, you agree to be bound by these Terms
					and our{' '}
					<b>
						<a href='/privacy-policy'>Privacy Policy</a>
					</b>{' '}
					(which is incorporated by reference). If you do not agree with these
					Terms or our Privacy Policy, you must not use the Site.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Scope of Services</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze offers personalized audio learning experiences by allowing:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Educators to upload pre-recorded audio courses along with
						supplementary resources.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Learners to subscribe to individual educators and access their
						Courses on a monthly basis.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					The Services include access to Courses and other related content,
					information, and functionalities provided on the Site (collectively,
					"Content"). However, learner subscriptions are limited to the content
					created by the educators they select and do not grant access to all
					Courses or Content on the Site.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Agreement Between You and Memoryze</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					These Terms constitute a binding agreement between you and Memoryze
					and govern:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Your use of the Site, whether as a visitor or a registered user.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Any content, services, or functionalities provided through the Site.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					Please review these Terms carefully. BY USING THE SITE, YOU
					ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED TO COMPLY WITH
					THEM.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Site License</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					We grant you a limited, non-exclusive, non-transferable, and
					non-sublicensable license to access and use the Memoryze platform (the
					"Site") and its content solely for personal, non-commercial purposes,
					subject to these Terms of Service (the "License"). This License allows
					you to interact with the Site as intended but does not permit
					redistribution, resale, or unauthorized use of any content or
					materials.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Ownership of Content</span>
			</h2>
			<ol className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Memoryze Content:</b> All content created or provided by
						Memoryze, including but not limited to text, graphics, logos,
						designs, software, and other proprietary materials ("Memoryze
						Content"), is the sole property of Memoryze and is protected under
						applicable copyright, trademark, and intellectual property laws.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Educator Content:</b> Educators retain ownership of the audio
						courses and accompanying resources they upload to the Site (
						"Educator Content"). By uploading content, Educators grant Memoryze
						a non-exclusive, worldwide, royalty-free license to host, display,
						and distribute their content to Learners subscribed to access their
						courses.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Learner Access:</b> Learners are granted a personal,
						non-transferable license to access Educator Content from their
						subscribed Educators. This access is for personal educational use
						only and may not be shared, reproduced, or distributed in any form.
					</span>
				</li>
			</ol>
			<h2 className='c2'>
				<span className='c9'>Prohibited Uses</span>
			</h2>
			<p className='c4'>
				<span className='c0'>You agree not to:</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Reverse-engineer, copy, or modify any part of the Site or its
						content.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Sell, redistribute, or sublicense any Memoryze Content or Educator
						Content.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Misuse the Site in ways that violate intellectual property laws or
						these Terms.
					</span>
				</li>
			</ul>
			<h2 className='c2'>
				<span className='c9'>Intellectual Property Rights</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze retains all rights, title, and interest in and to the Site,
					including:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						The platform's functionality, design, and software infrastructure.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Business processes, methodologies, and proprietary systems
						supporting the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Coordination and arrangement of content within the Site.
					</span>
				</li>
			</ul>
			<h2 className='c2'>
				<span className='c9'>Consequences of Violation</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Any violation of this License may constitute an infringement of
					intellectual property rights and a breach of these Terms. Violations
					may result in the suspension or termination of your account and could
					expose you to legal action, including civil and criminal penalties.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Third-Party Services and Affiliates</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					The Site may contain links, embedded content, or other connections to
					third-party websites, services, or resources (collectively,
					"Third-Party Services"), including affiliate marketing links. Memoryze
					may earn commissions from certain actions, such as purchases made
					through these affiliate links. However, Third-Party Services are
					independent from Memoryze, and their content and operations are beyond
					our control.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>By accessing Third-Party Services:</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						You acknowledge that Memoryze is not responsible for the content,
						functionality, legality, or accuracy of these services.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						You agree to use these services at your own risk and relieve
						Memoryze of any liability for losses or damages resulting from their
						use.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					If you believe any Third-Party Services violate applicable laws or are
					inappropriate, please notify us. However, the inclusion of Third-Party
					Services on our Site does not imply any endorsement or association
					with their operators. It remains your responsibility to evaluate the
					quality, reliability, and relevance of information or products from
					third parties.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Interactive Areas and User Content</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					The Site may provide areas where users can upload, share, and interact
					with content ("Interactive Areas"). By participating in these areas,
					you acknowledge and agree to the following terms:
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Public Visibility of User Content</span>
			</h2>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Content posted in Interactive Areas may be visible to other users
						and, in some cases, the public.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Your submissions may display your real name unless you select a
						screen name during registration.
					</span>
				</li>
			</ul>
			<h3 className='c2'>
				<span className='c9'>Ownership and License</span>
			</h3>
			<p className='c4'>
				<span className='c0'>
					You retain ownership of your User Content. However, by posting or
					submitting content, you grant Memoryze a{' '}
					<b>
						non-exclusive, worldwide, perpetual, royalty-free, transferable, and
						sublicensable license
					</b>{' '}
					to:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Use, reproduce, modify, adapt, distribute, and create derivative
						works from your content.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Operate, promote, and improve the Site using your content in any
						media format, now known or developed in the future.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					This license allows Memoryze to integrate your content into its
					promotional or operational activities without requiring payment,
					notification, or further permission from you.
				</span>
			</p>
			{/* continue from here up */}
			<h2 className='c2'>
				<span className='c9'>User Conduct</span>
			</h2>
			<p className='c4'>
				<span className='c0'>When using Interactive Areas, you agree to:</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Post only original content that you own or have the necessary rights
						to share.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Avoid posting any content that violates laws, infringes on others'
						rights, or is harmful, abusive, or misleading.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Not misrepresent your identity or affiliations.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					Memoryze does not actively monitor all content in Interactive Areas
					but reserves the right to intervene, remove content, or restrict user
					access at its sole discretion.
				</span>
			</p>

			<h3 className='c2'>
				<span className='c9'>Content Removal</span>
			</h3>

			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						You may remove your User Content at any time. Memoryze will stop
						using removed content in promotional materials.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>Despite removal, Memoryze:</span>
				</li>
				<ol className='c11'>
					<li className='c15 li-bullet-0'>
						<span className='c0'>
							May retain archived copies for legal and record-keeping purposes.
						</span>
					</li>
					<li className='c15 li-bullet-0'>
						<span className='c0'>
							Cannot guarantee third parties who previously accessed your
							content will stop using it.
						</span>
					</li>
				</ol>
			</ul>
			<h3 className='c2'>
				<span className='c9'>Liability Disclaimer</span>
			</h3>
			<p className='c4'>
				<span className='c0'>
					Memoryze is not responsible for interactions between users in
					Interactive Areas, including disputes or misconduct.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Users agree to hold Memoryze harmless from claims or liabilities
					arising from the actions of other users or their own use of the
					Interactive Areas.
				</span>
			</p>
			<h3 className='c2'>
				<span className='c9'>General Practices and Limits</span>
			</h3>
			<p className='c4'>
				<span className='c0'>
					Memoryze may set limits on the availability, storage, or accessibility
					of User Content. These limits may change without prior notice, and
					Memoryze is not liable for any content that is deleted or becomes
					inaccessible due to these changes.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>
					Digital Millennium Copyright Act (DMCA) Compliance
				</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze respects intellectual property rights and complies with the
					Digital Millennium Copyright Act (the "DMCA") to the best of its
					ability. At this time, Memoryze has not registered a designated agent
					with the United States Copyright Office. However, Memoryze takes
					copyright infringement claims seriously and will act promptly to
					address alleged violations in accordance with the DMCA.
				</span>
			</p>
			<h3 className='c2'>
				<span className='c9'>
					Notification of Alleged Copyright Infringement
				</span>
			</h3>
			<p className='c4'>
				<span className='c0'>
					If you believe in good faith that your copyrighted work has been used
					on the Site in a manner that constitutes infringement under applicable
					law, you may submit a written notice to us at{' '}
					<a className='c19' href='mailto:support@memoryze.co'>
						support@memoryze.co
					</a>{' '}
					with the following information:
				</span>
			</p>
			<ol className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Your contact information</b>, including your name, address,
						telephone number, and email address.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Identification of the copyrighted work</b> that you believe has
						been infringed.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Identification of the material and its location </b> on the Site
						(e.g., the URL or other sufficient detail for us to locate the
						material).
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						A statement that you have a good faith belief that the use of the
						material is not authorized by the copyright owner, its agent, or the
						law.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						A statement under penalty of perjury that the information provided
						in your notice is accurate and that you are the copyright owner or
						authorized to act on the copyright owner's behalf.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>6. Your electronic or physical signature.</span>
				</li>
			</ol>
			<p className='c4'>
				<span className='c0'>
					Please note that knowingly submitting a false notice may subject you
					to legal liability, including damages, court costs, and attorney fees
					under U.S. law.
				</span>
			</p>
			<h3 className='c2'>
				<span className='c9'>Counter-Notification to Restore Content</span>
			</h3>
			<p className='c4'>
				<span className='c0'>
					If you believe that material you posted was mistakenly or wrongfully
					removed as a result of a copyright claim, you may submit a
					counter-notification to us at{' '}
					<a className='c19' href='mailto:support@memoryze.co'>
						support@memoryze.co
					</a>{' '}
					with the following details:
				</span>
			</p>
			<ol className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Your contact information</b>, including your name, address,
						telephone number, and email address.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Identification of the material removed</b>, including a
						description of the material and its prior location on the Site
						(e.g., URL).
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						A statement that you have a good faith belief that the material was
						removed as a result of a mistake or misidentification.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						A statement under penalty of perjury that the information in your
						counter-notice is accurate.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						A statement that you consent to the jurisdiction of the U.S.
						District Court for the judicial district in which your address is
						located, or if you reside outside of the United States, the District
						of New Jersey. Additionally, you must state that you will accept
						service of process from the person who filed the original copyright
						notice or their agent.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>Your electronic or physical signature.</span>
				</li>
			</ol>
			<p className='c4'>
				<span className='c0'>
					Upon receipt of a valid counter-notification, Memoryze may reinstate
					the material in question unless the original complainant provides
					notice of court action seeking to prevent its restoration.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Policy on Repeat Infringers</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze will terminate, in appropriate circumstances, users who are
					determined to be repeat infringers.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Good Faith Efforts</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					While Memoryze has not registered a designated agent, we remain
					committed to addressing DMCA-related claims in good faith. If you have
					any questions or concerns about copyright infringement or the content
					removal process, please contact us at{' '}
					<a className='c19' href='mailto:support@memoryze.co'>
						support@memoryze.co
					</a>
					.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Limitations on Site Use</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					When using the Site, you are solely responsible for your interactions,
					including those in Interactive Areas, and must conduct yourself
					professionally and respectfully at all times. Your use of the Site,
					its Content, and other Users' User Content is subject to the following
					restrictions:
				</span>
			</p>
			<ol className='c11'>
				<li className='c15 li-bullet-0'>
					<b className='c2'>
						<span className='c9'>Prohibited Conduct</span>
					</b>
					<ul className='c11'>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Do not defame, harass, stalk, threaten, or violate the legal
								rights of Memoryze or other Users, whether on or off the Site.
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Avoid uploading or sharing content that is defamatory, obscene,
								unlawful, or promotes racism, hatred, or physical harm.
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Do not violate any local, state, federal, or international laws
								while using the Site.
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Do not use the Site for advertising, commercial purposes, or
								unauthorized solicitation unless agreed upon with Memoryze.
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Do not upload malicious software, viruses, or files that
								infringe on intellectual property or privacy rights.
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Avoid impersonating others, conducting fraudulent activities, or
								using deceptive practices on the Site.
							</span>
						</li>
					</ul>
				</li>
				<li className='c15 li-bullet-0'>
					<b className='c2'>
						<span className='c9'>Restricted Activities</span>
					</b>
					<ul className='c11'>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Do not collect User information or data through unauthorized
								means, including electronic scraping or data mining.
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Avoid interfering with the Site's functionality, including
								hacking or altering its servers or networks.
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Do not reverse-engineer, adapt, or sublicense the Site or its
								Content for personal or commercial use.
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Refrain from using or uploading materials that exploit minors or
								violate applicable child protection laws.
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Do not engage in any activity that competes with or harms the
								business of Memoryze.
							</span>
						</li>
					</ul>
				</li>
				<li className='c15 li-bullet-0'>
					<b className='c2'>
						<span className='c9'>License Termination</span>
					</b>
					<ul className='c11'>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								The license to use the Site terminates immediately upon any
								unauthorized use. Memoryze reserves the right to take
								investigative and legal action against violations, including
								restricting access or removing prohibited content.
							</span>
						</li>
					</ul>
				</li>
			</ol>
			<p className='c4'>
				<span className='c0'>
					By using the Site, you agree to comply with these terms. Violations
					may result in account termination, legal action, and other appropriate
					measures.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>
					Unlawful Activity and Termination of Site Access
				</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze reserves the right to investigate complaints or reported
					violations of these Terms of Use. We may take actions we deem
					appropriate, including but not limited to:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Reporting suspected unlawful activity to law enforcement,
						regulators, or relevant third parties.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Disclosing necessary user information (e.g., email addresses, usage
						history, User Content, IP addresses).
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					We may suspend or terminate your access to the Site, in whole or part,
					at our sole discretion and without prior notice if:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						You violate these Terms of Use, applicable law, or engage in conduct
						we find inappropriate or unacceptable.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						You file claims against Memoryze or involving the Site.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					If your access is terminated, these Terms of Use will remain
					enforceable. Memoryze reserves the right to pursue any available
					remedies for breaches or other violations.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					You may terminate these Terms of Use by ceasing all use of the Site.
					However, terms that, by their nature, should survive termination will
					remain in effect.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>User Accounts</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					To access certain functionalities of the Site, you may need to create
					an account ("Account") with the following commitments:
				</span>
			</p>
			<ol className='c11'>
				<li className='c15 li-bullet-0'>
					<b className='c0'>Account Creation</b>
					<ul>
						<li className='c15 li-bullet-0'>
							<span className='c0'>Create only one (1) unique Account.</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Provide accurate, current, and complete registration details
								(e.g., name, email, and other required data).
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Update your information promptly if requested by Memoryze.
							</span>
						</li>
					</ul>
				</li>
				<li className='c15 li-bullet-0'>
					<b className='c0'>Account Security</b>
					<ul>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Keep your login credentials confidential and secure.
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Avoid using offensive, profane, or unauthorized profile names or
								emails.
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Notify Memoryze immediately of any security breaches at{' '}
								<a className='c19' href='mailto:support@memoryze.co'>
									support@memoryze.co
								</a>
								.
							</span>
						</li>
					</ul>
				</li>
				<li className='c15 li-bullet-0'>
					<b className='c0'>Responsibility for Use</b>
					<ul>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								You are responsible for all activities under your Account, even
								if not authorized by you.
							</span>
						</li>
						<li className='c15 li-bullet-0'>
							<span className='c0'>
								Memoryze is not liable for losses or damages arising from
								unauthorized use of your Account or credentials.
							</span>
						</li>
					</ul>
				</li>
			</ol>
			<p className='c4'>
				<span className='c0'>
					By creating and using an Account, you agree to these terms and
					Memoryze's use of your Registration Data for operating the Site.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Courses, Fees and Payment</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze charges fees for accessing certain parts of the Site,
					particularly for subscribing to educators and their course catalogs
					("Courses"). By subscribing to an educator, you gain access to all
					Courses in their catalog for the duration of your subscription. Users
					may subscribe to multiple educators simultaneously, and these
					subscriptions operate independently.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Account and Payment Obligations</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					To access subscription-based content, you must:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Create an Account and maintain accurate, up-to-date registration
						details.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Pay all fees and charges incurred through your Account for the
						relevant billing period, including applicable taxes.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					All fees will be billed to you, and you are solely responsible for
					payment. Access to subscription-based content will only be granted
					once Memoryze confirms receipt of the applicable fees.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Refund Policy</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Refunds for subscriptions are available upon request within 7 days of
					the initial purchase, provided that subscription content has not been
					extensively accessed or downloaded. Beyond this 7-day period, refunds
					will not be granted under any circumstances.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Subscriptions initiated on or after the 26th of any month may
					encounter delays in refund processing due to the platform's payout
					schedule. Payouts to educators are processed on the 28th of each
					month.
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Refunds are contingent on the availability of funds in the
						educator's account balance. If the educator's account has already
						been settled and lacks sufficient funds, immediate refunds may not
						be possible.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						In cases where refunds cannot be immediately processed due to
						insufficient funds in the educator's account balance, Memoryze will
						make reasonable efforts to issue refunds during the next billing
						cycle. However, the availability of funds in the educator's account
						for subsequent cycles cannot be guaranteed.
					</span>
				</li>
			</ul>
			<h2 className='c2'>
				<span className='c9'>Taxes and Prepaid Fees</span>
			</h2>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Subscriptions and one-time payments may include applicable taxes,
						which you are responsible for paying.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Prepaid fees may apply for certain features, which must be paid in
						advance to gain access.
					</span>
				</li>
			</ul>
			<h2 className='c2'>
				<span className='c9'>Access and Violations</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					If your Account is banned or access is restricted due to a violation
					of the Terms of Use, no refunds will be provided for any fees or
					subscriptions.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Support and Inquiries</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					For questions regarding subscriptions, Courses, or related content,
					please contact Memoryze at{' '}
					<a className='c19' href='mailto:support@memoryze.co'>
						support@memoryze.co
					</a>
					.
				</span>
			</p>

			<h2 className='c2'>
				<span className='c9'>Representations and Warranties</span>
			</h2>
			<p className='c4'>
				<span className='c0'>The Site is available only to:</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Individuals who are <b>eighteen (18) years or older</b> (or the age
						of majority as defined by the applicable law in your jurisdiction);
						or
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Individuals between the ages of{' '}
						<b>thirteen (13) and eighteen (18)</b> who have obtained{' '}
						<b>parental or guardian permission</b> to use the Site.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					By accessing and using the Site, you represent and warrant that:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						You meet the age requirements or have obtained necessary
						parental/guardian consent if applicable.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						You have the <b>legal right, authority, and capacity</b> to enter
						into and comply with these Terms of Use.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>Additionally, you warrant that:</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Registration Data and User Content:</b> All information you
						submit, including Registration Data and User Content, is true,
						accurate, current, and complete.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Ownership of Content:</b> You own all rights or have obtained the
						necessary permissions for any content or information you upload to
						the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Non-infringement:</b> Your content does not infringe on
						third-party rights, including intellectual property, privacy, or
						publicity rights.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					You acknowledge that you are solely responsible for all Registration
					Data, User Content, and information you post or upload. Memoryze
					reserves the right to:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Review and Remove Content:</b> Reject, edit, or remove any User
						Content or information deemed non-compliant with these Terms or
						inappropriate for any reason.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>Do so without notice or liability to you.</span>
				</li>
			</ul>
			<h2 className='c2'>
				<span className='c9'>Advertisers on Site</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					The Site may display advertisements and sponsorships. Memoryze does
					not guarantee the accuracy or legality of any advertising or sponsored
					material and is not liable for:
				</span>
			</p>
			<ol className='c11'>
				<li className='c15'>
					<span className='c0'>
						Errors or inaccuracies in advertisers' or sponsors' submissions.
					</span>
				</li>
				<li className='c15'>
					<span className='c0'>
						Any acts or omissions by advertisers or sponsors.
					</span>
				</li>
			</ol>
			<p className='c4'>
				<span className='c0'>
					Advertisers and sponsors are solely responsible for ensuring their
					content complies with applicable laws and regulations.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Special Promotions</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					From time to time, Memoryze may offer promotional opportunities,
					sweepstakes, and contests ("Promotions") to Users. These Promotions:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Are subject to the sole discretion of Memoryze.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						May be <b>activated, modified, or removed</b> at any time without
						prior notice.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Are governed by these Terms of Use and any specific terms provided
						with the Promotion.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					The liability of Memoryze's partners concerning such Promotions will
					be limited as outlined in these Terms of Use.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Communications</span>
			</h2>
			<div className='c4'>
				<span className='c0'>
					By providing Memoryze with your email address and using the Site, you
					consent to receive:
					<ol>
						<li>
							<strong>Service and Administrative Notifications</strong>:
							Essential announcements related to your use of the Site.
						</li>
						<li>
							<strong>Marketing Communications</strong>: Advertising and
							promotional messages from Memoryze and third-party advertising
							partners, as outlined in our Privacy Policy.
						</li>
					</ol>
					You may opt out of certain marketing notifications by following the
					instructions in our Privacy Policy. However, if you do not wish to
					receive essential service and administrative notifications, your only
					option is to discontinue use of the Site.
				</span>
			</div>
			<div className='c4'>
				<span className='c0'>
					<b>Third-Party Communications</b>:
					<ul>
						<li>
							<b>Liability Disclaimer:</b> Memoryze is not liable for
							communications you receive from third parties ("Third-Party
							Communications"), whether directly or indirectly connected to the
							Site.
						</li>
						<li>
							<b>User Responsibility:</b> You are solely responsible for
							evaluating the identity, trustworthiness, and content of any
							Third-Party Communications and for verifying their source.
						</li>
						<li>
							<b>Release of Liability:</b> By using the Site, you release and
							hold Memoryze harmless from any loss, damage, or liability
							resulting from Third-Party Communications or your interactions
							with third parties.
						</li>
					</ul>
					It is your responsibility to use caution and assess the usefulness of
					information obtained from third parties.
				</span>
			</div>
			<br />
			<h2 className='c2'>
				<span className='c9'>Disclaimers</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Your use of the Site is at your own risk. The Site is provided on an
					"as is" and "as available" basis, with no warranties of any kind,
					whether express or implied. This includes, but is not limited to:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Implied Warranties:</b> Non-infringement, merchantability, and
						fitness for a particular purpose.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Content Accuracy:</b> Memoryze does not guarantee the accuracy or
						completeness of the Site's content or any third-party content linked
						to the Site.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					<b>No Liability for:</b>
				</span>
			</p>
			<ol>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Errors or Inaccuracies:</b> In the Site's content or materials.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Personal Injury or Property Damage:</b> Resulting from your use
						of the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Unauthorized Access:</b> To servers or personal information
						stored within.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Third-Party Services:</b> Memoryze does not endorse or guarantee
						services or products offered by third parties through the Site.
						Transactions with such providers are your responsibility.
					</span>
				</li>
			</ol>
			<h2 className='c2'>
				<span className='c9'>Additional Disclaimers:</span>
			</h2>
			<ul>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Site Availability:</b> Memoryze does not warrant uninterrupted or
						error-free access to the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Results and Reliability:</b> No guarantees are made regarding
						outcomes, timeliness, or reliability of content accessed through the
						Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>User Conduct:</b> Memoryze is not responsible for the actions of
						users, whether online or offline.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Malware Protection:</b> The Site is not warranted to be free from
						viruses, worms, or harmful components.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Data Security:</b> Memoryze does not guarantee the security of
						personal information against interception, misappropriation, or
						unauthorized use.
					</span>
				</li>
			</ul>

			<h2 className='c2'>
				<span className='c9'>California Civil Code § 1542 Waiver:</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					To the extent applicable, you waive the protections of California
					Civil Code § 1542 (and similar laws in other jurisdictions), which
					states:
				</span>
			</p>
			<p className='c4' style={{ marginLeft: '20px' }}>
				<span className='c0'>
					"A general release does not extend to claims which the creditor does
					not know or suspect to exist in his or her favor at the time of
					executing the release, which if known by him or her must have
					materially affected his or her settlement with the debtor."
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Limitation of Liability</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					You acknowledge and agree that Memoryze is only willing to provide the
					Site under certain limitations of liability. Therefore, you agree not
					to hold Memoryze or its affiliates (including its parents,
					subsidiaries, directors, officers, employees, content partners,
					vendors, third-party licensors, distributors, advertisers, or any of
					their respective agents) liable for any damages, claims, or
					controversies (collectively referred to as "Liabilities") arising out
					of your or any other party's use of or inability to use the Site.
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>User Conduct:</b> Including stalking, harassment, physical
						violence, and property destruction.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Disputes:</b> Between users or with Memoryze.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Services or Advice:</b> Provided by Memoryze or its affiliates.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Destruction of Information:</b> Including personal data.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					Under no circumstances will Memoryze or its affiliates be liable for:
				</span>
			</p>
			<ol className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Direct, Indirect, Incidental, Consequential, Special, or
						Exemplary Damages:</b> Arising from the use or inability to use the
						Site, even if we were advised of the possibility of such damages.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Limitations:</b> Some states do not allow the exclusion or
						limitation of incidental or consequential damages, so these
						exclusions may not apply to you.
					</span>
				</li>
			</ol>
			<p className='c4'>
				<span className='c0'>
					In the event that Memoryze or its affiliates are found liable, the
					total liability (whether arising in contract, tort, or otherwise) will
					not exceed <b>$100.00 USD</b>.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Additionally, both you and Memoryze agree that any disputes will be
					resolved on an individual basis and not as part of a{' '}
					<b>class action, representative action</b>, or{' '}
					<b>collective action</b>. No proceedings will be consolidated or
					combined with another without prior written consent.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Indemnification</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					You agree to indemnify, defend, and hold harmless Memoryze, its
					affiliates, and their respective directors, officers, employees,
					vendors, content partners, third-party licensors, distributors,
					advertisers, and other agents from any losses, liabilities, damages,
					costs, or expenses (including legal fees) arising from:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Your use of, or inability to use, the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Any breach of your representations, warranties, or obligations under
						these Terms of Use.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Registration Data or User Content submitted by you.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Your violation of any applicable law or regulation.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Your violation of third-party rights, including intellectual
						property rights.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					Memoryze will promptly notify you of any such claims, and you will
					assume control of the defense of such claims upon Memoryze's request.
					However, Memoryze reserves the right to assume exclusive defense and
					control over the matter at its expense. You may not settle any claim
					without Memoryze's written consent.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Errors and Corrections; Updates</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze does not guarantee that the Site, Site Content, or User
					Content will be free from errors, viruses, or other harmful
					components. Furthermore, we do not warrant that defects will be
					corrected or that the Site will always be accessible. We do not
					guarantee that the Content available on or through the Site will be
					accurate, up-to-date, reliable, or error-free.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Your access to the Site may be disrupted by various factors outside of
					Memoryze's control, such as telecommunications issues, network service
					disruptions, device malfunctions, viruses, bugs, tampering,
					unauthorized intervention, fraud, technical failures, and defects in
					servers, equipment, or software. Memoryze is not responsible for any
					failures related to the Internet or the data/telecommunications
					equipment, systems, or networks used in connection with the Site.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Perfect security does not exist on the Internet, and Memoryze cannot
					and does not guarantee that any Registration Data or personally
					identifiable information you submit to the Site will not be made
					public under any circumstances.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Memoryze may update the Site in the future by modifying or adding new
					Site Content and/or features (an "Update"). These updates may cause
					temporary suspensions of the Site or its Content, and Memoryze will
					not be liable to you for any interruptions related to such Updates.
					Furthermore, Memoryze is under no obligation to update the Site if the
					Content or features are outdated. The terms of the License granted to
					you will continue to apply in full to any Updates.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Privacy</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Your privacy is very important to Memoryze. To understand our
					practices, please review our Privacy Policy, which is incorporated by
					reference into these Terms of Use and also governs your use of the
					Site. Likewise, you represent and warrant that you will respect the
					privacy, property, and data protection rights of Users.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>
					Governing Law and Jurisdiction; Disputes & Arbitration
				</span>
			</h2>
			<p className='c4'>
				These Terms shall be governed by the laws of the State of Delaware, USA,
				without regard to conflict of law principles.
			</p>
			<p className='c0'>
				Any disputes or differences arising under, relating to, or connected
				with these Terms shall first be resolved by mediation. If mediation
				fails, such disputes shall be resolved by final and binding arbitration
				administered by the American Arbitration Association (AAA) in accordance
				with its Commercial Arbitration Rules in effect at the time.
			</p>
			<p className='c0'>
				The arbitration shall be conducted by a sole arbitrator, mutually
				appointed by the Parties. The Parties further agree that the courts
				situated in the State of Delaware shall have exclusive jurisdiction over
				any dispute arising from these Terms, including enforcement of
				arbitration awards.
			</p>
			<p className='c0'>
				You agree that, regardless of any contrary law, any claims related to
				your use of the Site or these Terms must be filed within{' '}
				<b>one (1) year</b> of when the claim arises, or the claim will be
				barred.
			</p>
			<h2 className='c2'>
				<span className='c9'>Assignments</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze may assign, subcontract, or transfer any or all of our rights
					and obligations under these Terms of Use to any company, firm, or
					individual. You may not transfer your rights or obligations under
					these Terms to anyone else.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>
					Waiver and Severability of Terms; Entire Agreement
				</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Failure by Memoryze to enforce any provision(s) of these Terms of Use
					will not be considered a waiver of that provision or right. If any
					provision of these Terms is found to be unlawful, void, or
					unenforceable by a court with proper jurisdiction, that provision will
					be severed from the remaining provisions and will not affect their
					validity or enforceability. These Terms of Use, along with any notices
					found on the Site and the Privacy Policy, constitute the entire
					agreement regarding your access to and use of the Site, Site Content,
					Interactive Areas, and User Content.
				</span>
			</p>

			<h2 className='c2'>
				<span className='c9'>Contact</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					For questions or comments about the Site, our services, or these Terms
					of Use, please contact us at{' '}
				</span>
				<span className='c18'>
					<a className='c19' href='mailto:support@memoryze.co'>
						support@memoryze.co
					</a>
				</span>
				<span className='c0'>.</span>
			</p>
			<p className='c4 c7'>
				<span className='c0'></span>
			</p>
			<p className='c4 c7'>
				<span className='c0'></span>
			</p>
			<p className='c4 c7'>
				<span className='c0'></span>
			</p>
			<p className='c7 c14'>
				<span className='c12'></span>
			</p>
			<p className='c14 c7'>
				<span className='c12'></span>
			</p>
			<p className='c4 c7'>
				<span className='c0'></span>
			</p>
			<p className='c7 c20'>
				<span className='c0'></span>
			</p>
		</main>
	);
}

export default TermsOfServices;
