import React, { useState, useEffect } from 'react';
import {
	Alert,
	Avatar,
	Box,
	Button,
	Card,
	CardHeader,
	styled,
	Typography,
	Link,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { connect } from 'react-redux';
import LinksDisplay from './LinksDisplay';
import { ICourseListItem, ILink, IUser, ToasterType } from '../../types/interfaces';
import { axiosDefault } from '../../api/axios';
import { USERS_URL } from '../../api/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { ProfileService } from '../../api/profileService';
import CourseCard from '../Shared/CourseCard';
import MembershipDescriptionModal from '../Shared/MembershipDescriptionModal/MembershipDescriptionModal';
import { PaymentService } from '../../api/paymentsService';
import { openMobileAppFeature } from '../../api/utils';
import { ToasterService } from '../../MainUtils/ToasterService';
import { ToasterDuration } from '../../types/constants';

const mapStateToProps = (state) => ({
	currentUser: state.userData,
});

const mapDispatchToProps = (dispatch) => ({
	setShowProfileEditNameDrawer: (val) =>
		dispatch({ type: 'SET_PROFILE_EDIT', payload: val }),
	setShowBecomeEducatorModal: (val) =>
		dispatch({ type: 'SET_BECOME_EDUCATOR_MODAL', payload: val }),
});

function Profile({ currentUser }: { currentUser: IUser }) {
	const [openMembershipDescModal, setOpenMembershipDescModal] = useState(false);

	useEffect(() => {
		// Scroll to the top of the page
		window.scrollTo(0, 0);
	}, []);
	const { id } = useParams(); // Capture the course ID from the URL
	const navigate = useNavigate();
	const [userNotFound, setUserNotFound] = useState(false);
	const [otherError, setOtherError] = useState(false);

	const [profileUser, setProfileUser] = useState<IUser>();
	const [courses, setCourses] = useState<ICourseListItem[]>();
	const isEducator = profileUser?.is_tutor;

	useEffect(() => {
		if (!id) {
			navigate('/app/search');
			return;
		}
		axiosDefault(USERS_URL + id)
			.then((res) => {
				setProfileUser(res.data);
				PaymentService.setMembershipDetails(
					res.data.membership_id,
					res.data.membership_price_in_dollars,
					res.data.name,
					res.data?.profile_image || ''
				);
			})
			.catch((error) => {
				if (error.status === 404) {
					setUserNotFound(true);
				} else {
					setOtherError(true);
				}
			});

		ProfileService.loadCoursesByEducator(+id, 20, false).then(({ courses }) => {
			setCourses(courses);
		});
	}, []);
	const CategoriesRow = styled(Box)({
		display: 'flex',
		flexDirection: 'row',
		paddingVertical: '8px', // You may adjust this as needed
		paddingBottom: 10,
		overflowX: 'auto', // Enables horizontal scrolling
		whiteSpace: 'nowrap',
	});
	const handleShare = async () => {
		try {
			// Get the current URL
			const currentUrl = window.location.href;

			// Copy to clipboard
			await navigator.clipboard.writeText(currentUrl);

			// Show success message using ToasterService
			ToasterService.dispatchAddToaster({
				message: 'Profile link copied to clipboard!',
				type: ToasterType.SUCCESS,
				duration: ToasterDuration.SHORT,
			});
		} catch (error) {
			// Show error message if copying fails
			ToasterService.dispatchAddToaster({
				message: 'Failed to copy link. Please try again.',
				type: ToasterType.ERROR,
				duration: ToasterDuration.SHORT,
			});
		}
	};

	return (
		<>
			{otherError ? (
				<Alert severity='error'>
					An error occurred while retrieving account.
				</Alert>
			) : userNotFound ? (
				<Alert severity='error'>User Not Found.</Alert>
			) : !!profileUser ? (
				<>
					<Box sx={{ backgroundColor: '#f5f5f5', pb: 3, mb: 0 }}>
						<Card
							sx={{
								backgroundColor: 'white',
								borderRadius: 8,
								padding: 2,
								display: 'flex',
								alignItems: 'center',
							}}>
							<Avatar
								alt={profileUser?.name}
								src={
									profileUser?.profile_image ||
									'/images/defaultEducatorProfileImage.png'
								}
								sx={{ width: 80, height: 80 }}
							/>

							<div style={{ width: '100%' }}>
								<div
									style={{
										display: 'flex',

										justifyContent: 'space-between',

										backgroundColor: '#FFFFFF',
									}}>
									<CardHeader
										title={profileUser?.name || 'Educator'}
										subheader={`@${profileUser?.username}`}
										sx={{ paddingLeft: 1.5 }}
										titleTypographyProps={{
											variant: 'body1', // You can change the variant here
											sx: {
												fontWeight: 'bold', // Example style for the title
												color: '#121212', // Example color for the title
												fontSize: 18,

												lineHeight: 1.2, // Adjust line height as needed
												maxHeight: '72px', // 3 lines * line-height
												overflow: 'hidden',
												display: '-webkit-box',
												WebkitBoxOrient: 'vertical',
												WebkitLineClamp: 3,

												overflowWrap: 'break-word', // Allows breaking long words
												wordWrap: 'break-word', // Legacy support
												hyphens: 'auto', // Adds hyphenation
												textOverflow: 'ellipsis', // Optional: adds ellipsis for overflowing text
												whiteSpace: 'normal', // Allows the text to wrap
											},
										}}
										subheaderTypographyProps={{
											variant: 'subtitle1', // You can change the variant here
											sx: {
												color: '#121212', // Example color for the subheader
												fontStyle: 'italic', // Example style for the subheader
											},
										}}
									/>
									{profileUser?.is_subscribed && (
										<div
											style={{
												height: 30,
												width: 30,
												marginTop: 10,
												borderRadius: '50%',
												borderWidth: 0.5,
												borderColor: '#121212',
												borderStyle: 'solid',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												backgroundColor: '#FFFFFF',
											}}>
											<FavoriteIcon
												sx={{ mx: 1, color: '#E74141', fontSize: 20 }}
											/>
										</div>
									)}
								</div>
								<div
									style={{
										display: 'flex',
										flex: 1,
										justifyContent: 'space-between',
										alignItems: 'center',
										marginLeft: 15,
									}}>
									<Typography sx={{ fontSize: 14 }}>{`${
										profileUser?.total_subscriptions ?? 0
									} Members`}</Typography>
									{isEducator && (
										<Button
											// variant='outlined'
											onClick={handleShare}
											sx={{
												marginLeft: 2,
												fontSize: 12,
												height: 35,
												paddingVertical: 7,
												backgroundColor: '#F4F5F9',
												borderRadius: 100,
												color: '#121212',
											}}>
											Share profile
										</Button>
									)}
								</div>
							</div>
						</Card>
					</Box>
					<Alert severity='info' sx={{ mt: 1, mb: 2 }}>
						Listen and experience more features on the mobile app.{' '}
						<Link
							component='button'
							variant='body1'
							onClick={() =>
								openMobileAppFeature(`educator/${profileUser?.id}`)
							}
							sx={{ color: '#2F69F8', textDecorationColor: '#2F69F8' }}>
							Open App
						</Link>
					</Alert>

					{currentUser?.id !== profileUser?.id &&
						!profileUser?.is_subscribed &&
						profileUser?.is_tutor && (
							<Button
								fullWidth
								variant='outlined'
								color='primary'
								disabled={!profileUser?.allow_subscriptions}
								sx={{ mb: 3 }}
								onClick={() => {
									setOpenMembershipDescModal(true);
								}}>
								Gain Access
							</Button>
						)}
					<MembershipDescriptionModal
						open={openMembershipDescModal}
						handleClose={() => {
							setOpenMembershipDescModal(false);
						}}
						educatorName={profileUser?.name}
						price={profileUser?.membership_price_in_dollars}
						membershipId={profileUser?.membership_id}
					/>
					<Card sx={{ p: 2, mb: 2, backgroundColor: 'white', borderRadius: 5 }}>
						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'>
							<Typography variant='h6'>
								<img
									src='/images/Icons/bio.png'
									alt='course icon'
									style={{
										marginRight: 7,
										height: 20,
										width: 20,
										marginBottom: 5,
									}}
								/>
								Bio
							</Typography>
						</Box>
						<Typography variant='body2' sx={{ mt: 1 }}>
							{profileUser?.bio || 'No bio available.'}
						</Typography>
					</Card>

					<Card sx={{ p: 2, mb: 2, backgroundColor: 'white', borderRadius: 5 }}>
						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'>
							<Typography variant='h6'>
								<img
									src='/images/Icons/links.png'
									alt='course icon'
									style={{
										marginRight: 7,
										height: 20,
										width: 22,
										marginBottom: 5,
									}}
								/>
								Links
							</Typography>
						</Box>
						<LinksDisplay links={profileUser?.links as ILink[]} />
					</Card>

					{courses && courses.length > 0 && (
						<Typography variant='h6' sx={{ mt: 4, mb: 2 }}>
							<img
								src='/images/Icons/course-black.png'
								alt='course icon'
								style={{
									marginRight: 7,
									height: 20,
									width: 22,
									marginBottom: 3,
								}}
							/>
							Course(s)
						</Typography>
					)}

					<CategoriesRow>
						{courses?.map((course, index) => (
							<CourseCard key={index} course={course} />
						))}
					</CategoriesRow>
				</>
			) : null}
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
