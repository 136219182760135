import { axiosDefault } from './axios';
import { CREATE_STRIPE_SUBSCRIPTION_URL, UPDATE_DEFAULT_PAYMENT_METHOD_URL, DEFAULT_PAYMENT_METHOD_URL, STRIPE_CREATE_UPDATE_ACCOUNT_LINK_URL } from './constants';
import { IUser, IPaymentMethod } from '../types/interfaces';
import { store } from '../store/store';
import { setEducatorMembershipData, setUserData} from '../store/actions';



interface CreateSubscriptionParams {
  membership_id: string;
  payment_method_id?: string;
}

interface SubscriptionResponse {
  user: IUser;
}

export class PaymentService {
  static async getPaymentMethodData(): Promise<IPaymentMethod> {
    const response = await axiosDefault.get(DEFAULT_PAYMENT_METHOD_URL);
    return response.data.payment_method;
  }

  static async updatePaymentMethod(paymentMethodId: string): Promise<void> {
    await axiosDefault.post(UPDATE_DEFAULT_PAYMENT_METHOD_URL, {payment_method_id: paymentMethodId});
  }

  static async createSubscription(params: CreateSubscriptionParams): Promise<SubscriptionResponse> {
    const response = await axiosDefault.post(CREATE_STRIPE_SUBSCRIPTION_URL, params);
    return response.data;
  }
  static async setMembershipDetails(membershipId: number, membershipAmount: number, educatorName: string, educatorProfileImage: string): Promise<void> {
		store.dispatch(
			setEducatorMembershipData({
				membershipAmount,
				membershipId,
				educatorName,
				educatorProfileImage,
			})
		);
	}

  static async getStripeAccountLink(): Promise<string> {
    const response = await axiosDefault.post(STRIPE_CREATE_UPDATE_ACCOUNT_LINK_URL);
    store.dispatch(setUserData(response.data.user));
    return response.data.url;
  }
}
