import React from 'react';
const Logo = '/images/Logo.png';
export const socialMediaIcons = {
	instagram: '/images/vectors/landingPage/instagramIcon.png', // Direct path
	facebook: '/images/vectors/landingPage/facebookIcon.png', // Direct path
	twitter: '/images/vectors/landingPage/twitterIcon.png', // Direct path
	linkedin: '/images/vectors/landingPage/linkedInIcon.png', // Direct path
	tiktok: '/images/vectors/landingPage/tiktokIcon.png', // Direct path
	threads: '/images/vectors/landingPage/threads-logo-black-01.png', // Direct path
	blueSky: '/images/vectors/landingPage/bluesky-1.svg', // Direct path
};

import './Footer.css';
function Footer() {
	return (
		<footer className='footer'>
			<img src={Logo} alt='logo' className='logo-icon' />
			<div className='social-links'>
				<a
					href='https://www.instagram.com/memoryzehq/'
					target='_blank'
					rel='noopener noreferrer'>
					<img
						src={socialMediaIcons.instagram}
						alt='logo'
						className='social-icon'
					/>
				</a>

				<a
					href='https://www.linkedin.com/company/memoryze-co/about/'
					target='_blank'
					rel='noopener noreferrer'>
					<img
						src={socialMediaIcons.linkedin}
						alt='logo'
						className='social-icon'
					/>
				</a>
				<a
					href='https://www.threads.net/@memoryzehq'
					target='_blank'
					rel='noopener noreferrer'>
					<img
						src={socialMediaIcons.threads}
						alt='logo'
						className='social-icon'
					/>
				</a>
				{/* <a
					href='https://twitter.com/MemoryzeHQ'
					target='_blank'
					rel='noopener noreferrer'>
					<img
						src={socialMediaIcons.twitter}
						alt='logo'
						className='social-icon'
					/>
				</a> */}
				<a
					href='https://www.tiktok.com/@memoryzehq?_t=ZT-8spOsutJ3us&_r=1'
					target='_blank'
					rel='noopener noreferrer'>
					<img
						src={socialMediaIcons.tiktok}
						alt='logo'
						className='social-icon'
					/>
				</a>
				<a
					href='https://bsky.app/profile/memoryzehq.bsky.social'
					target='_blank'
					rel='noopener noreferrer'>
					<img
						src={socialMediaIcons.blueSky}
						alt='BlueSky'
						className='social-icon'
					/>
				</a>
			</div>
			<p
				style={{
					fontWeight: 'bold',
					fontSize: 0.75 + 'rem',
					marginTop: 30 + 'px',
				}}>
				<span style={{ fontSize: 1 + 'rem', paddingTop: 75 + 'px' }}>
					&copy;
				</span>{' '}
				Memoryze, {new Date().getFullYear()}. All rights reserved
			</p>
			<p>
				<a href='/terms' className='footer-link'>
					Terms of services{' '}
				</a>
				|{' '}
				<a href='/privacy-policy' className='footer-link'>
					Privacy Policy
				</a>
			</p>
		</footer>
	);
}

export default Footer;
