import { ToasterDuration } from './constants';

export interface IVerificationScreenState {
	seconds: number;
	timerSeconds: number;
	minutes: number;
	expired: boolean;
	code: string;
	apiCode: string | number;
	wrongCode: boolean;
}

export enum ToasterType {
	SUCCESS = 'success',
	ERROR = 'error',
	INFO = 'info',
	WARNING = 'warning',
}

export interface IToasterOptions {
	message: string;
	duration: ToasterDuration;
	type: ToasterType;
}
export interface IToaster {
	id: number;
	isVisible: boolean;
	message: string;
	duration: number;
	type: ToasterType;
}

export interface IResource {
	id: number;
	title: string;
	file: string;
	description: string;
	course_id: number;
	resource_bytes: number;
}

export interface IAudio {
	id: number;
	index: number;
	title: string;
	recording: string;
	description: string;
	downloaded: false;
	tutor_id: number;
	duration_millisecs: number;
	progress_millis: number;
	tutor_name: string;
	tutor_username: string;
	course_id: number;
	course_name: string;
	course_image: string;
	is_liked: boolean;
	like_id: number | null;
	likes: ILike[];
	total_likes: number;
	lesson_library_id: number | null;
	course_library_id: number | null;
	course_downloaded: boolean | null;
	image_bytes: number;
	recording_bytes: number;
}

export interface ICourseListItem {
	id: number;
	name: string;
	description: string;
	tutor_id: number;
	tutor_name: string;
	tutor_username?: string | null;
	image_bytes: number; // if needed as a number, override here
	descriptive_image: string;
	categories: string[];
	tags: string[];
	make_public: boolean;
	reviewing: boolean;
	listen_time_millis: number;
	summary_list: string[];
	created_at: string;
	updated_at: string;
}

export interface ICourse extends ICourseListItem {
	tutor_profile_image: string | null;
	tutor_profile_image_bytes: number | null;
	tutor_bio: string;
	audios: IAudio[] | null;
	resources: IResource[];
	has_lesson_downloaded: boolean;
	has_access: boolean;
	allow_subscriptions: boolean;
	preview_count: number;
	is_saved: boolean;
	downloaded: boolean;
	library_id: number | null;
	image_bytes: number; // if needed as a number, override here
	membership_id: number;
	membership_price_in_dollars: number;
	stripe_product_id: string; //the product id of the membership
}

export interface ILink {
	label: string;
	link: string;
}
export interface IUserListItem {
	id: number;
	name: string;
	username: string;
	profile_image: string | null;
	is_tutor: boolean;
}
export interface IUser {
	id: number;
	name: string;
	username: string;
	email: string;
	profile_image: string | null;
	bio: string;
	code: string;
	is_private: boolean;
	is_verified: boolean;
	is_tutor: boolean;
	is_social_auth: boolean;
	is_new_oauth_user: boolean;
	created_at: string;
	updated_at: string;
	interests: string[];
	image_bytes: number;
	preview_count: number;
	previews_reset_date: string;
	email_member_alert: boolean;
	push_member_alert: boolean;
	email_on_new_content: boolean;
	push_on_new_content: boolean;
	email_recommendations: boolean;
	links: ILink[];
	is_subscribed?: boolean;
	total_subscriptions: number;
	membership_id: number;
	membership_price_in_dollars: number;
	last_feedback_date: string;
	is_being_reviewed: string;
	stripe_product_id: string;
	has_payment_method: boolean | null;
	allow_subscriptions: boolean | null;
	started_connect_setup: boolean | null;
	subscription_id: number | null; // this is for the user making the request
	is_pending_delete: boolean;
}
export interface ISubscription {
	id: number;
	membership_id: number;
	user_id: number; // The ID of the user who created the subscription
	subscription_name: string; // The name of the subscription
	membership_educator_id: number; // The ID of the educator who owns the membership
	membership_educator_name: string; // The name of the educator who owns the membership
	membership_educator_profile_image: string; // The profile image URL of the educator who owns the membership
	membership_price_in_dollars: number;
	created_at: string;
	updated_at: string;
	start_date: string;
	end_date: string;
	is_active: boolean;
	is_cancelled: boolean;
	billing_date: string;
	status: string;
}

export interface SubscribedEducator {
	id: number;
	name: string;
	username: string;
	profile_image: string | null;
	membership_id: number | null;
	subscription_id: number | null;
}

export interface IMembership {
	id: number; // The unique identifier for the membership
	user_id: number; // The ID of the user who created the membership
	name: string; // The name of the membership
	created_at: string; // The creation date of the membership in ISO string format (e.g., "2024-08-12T20:45:45.142634Z")
	subscriptions: ISubscription[]; // An array of Subscription objects related to the membership
	description: string;
	price_in_dollars: number;
	stripe_product_id: string;
	stripe_price_id: string;
}

export interface ILike {
	id: number;
	audio_id: number;
	user_id: number;
}

export interface ISavedCourse {
	id: number;
	course_id: number;
	user_id: number;
	course: ICourse;
	downloaded: boolean;
	has_lesson_downloaded: boolean;
}
export interface ISearchedEducator {
	id: number;
	educator_id: number;
	user_id: number;
	educator: IUser;
}
export interface ISearchedCourse {
	id: number;
	course_id: number;
	user_id: number;
	course: ICourseListItem;
}

export interface ISearchedAudio {
	id: number;
	audio_id: number;
	user_id: number;
	audio: IAudio;
}

export interface IPlayedCourse {
	id: number;
	course_id: number;
	user_id: number;
	course: ICourseListItem;
}

export interface IPlayedAudio {
	id: number;
	audio_id: number;
	user_id: number;
	audio: IAudio;
}
export interface ICourseListenTime {
	listen_time: string;
	course_id: number;
}

export interface ICourseCategory {
	category: string;
	courses: ICourseListItem[];
}
export enum SearchDataType {
	AUDIO = 'audio',
	COURSE = 'course',
	EDUCATOR = 'educator',
}
export enum ModalStackType {
	COURSE = 'COURSE',
	PROFILE = 'PROFILE',
	MEMBERS = 'MEMBERS',
}
export interface IModalStackObject {
	type: ModalStackType;
	id: number; //for MEMBERS, the id would be the id of the userData
}

export type IModalStackScreensData = {
	[screen: string]: {
		[key: number]: IModalStackObject;
	};
};

export interface ISearchResultDataObj {
	type: SearchDataType;
	data: ICourse | IAudio | IUser;
}
export interface ISearchHistoryData {
	type: SearchDataType;
	data: ISearchedCourse | ISearchedAudio | ISearchedEducator;
}

export interface ICourseObjList {
	[id: number]: { course: ICourse; displayCount: number };
	//where id is the course's id
}
export interface IEducatorProfileData {
	courses: ICourse[];
	coursesCount: number;
	drafts: ICourse[];
	draftsCount: number;
}

export interface ProfileListItem {
	[id: number]: {
		profile: IEducatorProfileData;
		educator: IUser;
		displayCount: number;
	}; //where id is the id of the user/educator
}

export interface IPasswordCheck {
	has6Chars: boolean;
	hasUpperAndLowerCase: boolean;
	hasNumber: boolean;
	hasSymbol: boolean;
}

export interface IPaymentMethodCard {
	brand: string;
	last4: string;
	exp_month: number;
	exp_year: number;
}

export interface IPaymentMethod {
	id: string;
	card: IPaymentMethodCard;
	type: string;
}
export interface IEducatorMembershipData {
	membershipAmount?: number; // for subscribe to educator screen
	membershipId?: number; // for subscribe to educator screen
	educatorName?: string; // for subscribe to educator screen
	educatorProfileImage?: string; // for subscribe to educator screen
}

export interface IStore {
	userData: IUser | null;
	educatorProfileData?: IEducatorProfileData; //for user profile screen
	courseCategories: ICourseCategory[];
	searchHistory: ISearchHistoryData[];
	educatorMembershipData?: IEducatorMembershipData; // for subscribe to educator screen
}
