import React, { useState } from 'react';
import {
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Card,
	Avatar,
	CardHeader,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IUser } from '../../types/interfaces';
import StripeConnectAccountUpdate from './StripeConnectAccountUpdate';
import { useNavigate } from 'react-router-dom';
import { ProfileService } from '../../api/profileService';

interface StateProps {
	userData: IUser | null;
}

interface ProfileCardProps {
	userData: IUser | null;
}

const ProfileCard = ({ userData }: ProfileCardProps) => {
	const navigate = useNavigate();
	const viewProfile = () => {
		navigate(`/app/educators/${userData?.id}`);
	};
	return (
		<Box sx={{ backgroundColor: '#f5f5f5', mb: 0 }}>
			<Card
				sx={{
					backgroundColor: 'white',
					borderRadius: 8,
					padding: 2,
					display: 'flex',
					alignItems: 'center',
				}}>
				<Avatar
					alt={userData?.name || 'Educator'}
					src={
						userData?.profile_image
							? userData?.profile_image
							: userData?.is_tutor
							? '/images/defaultEducatorProfileImage.png'
							: '/images/defaultLearnerProfileImage.png'
					}
					sx={{ width: 75, height: 75 }}
				/>

				<div style={{ width: '100%' }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							backgroundColor: '#FFFFFF',
						}}>
						<CardHeader
							title={userData?.name || 'Educator'}
							subheader={`@${userData?.username}`}
							sx={{ paddingLeft: 1.5 }}
							titleTypographyProps={{
								variant: 'body1',
								sx: {
									fontWeight: 'bold',
									color: '#121212',
									fontSize: 18,
									lineHeight: 1.2,
									maxHeight: '72px',
									overflow: 'hidden',
									display: '-webkit-box',
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: 3,
									overflowWrap: 'break-word',
									wordWrap: 'break-word',
									hyphens: 'auto',
									textOverflow: 'ellipsis',
									whiteSpace: 'normal',
								},
							}}
							subheaderTypographyProps={{
								variant: 'subtitle1',
								sx: {
									color: '#121212',
									fontStyle: 'italic',
								},
							}}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							flex: 1,
							justifyContent: 'space-between',
							alignItems: 'center',
							marginLeft: 15,
						}}>
						<Typography sx={{ fontSize: 14 }}>{`${
							userData?.total_subscriptions ?? 0
						} Members`}</Typography>
						{userData?.is_tutor && (
							<Button
								onClick={viewProfile}
								sx={{
									marginLeft: 1,
									fontSize: 12,
									height: 35,
									paddingVertical: 7,
									backgroundColor: '#F4F5F9',
									borderRadius: 100,
									color: '#121212',
								}}>
								View profile
							</Button>
						)}
					</div>
				</div>
			</Card>
		</Box>
	);
};

function AccountTab({ userData }: StateProps) {
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const handleDeleteAccount = async () => {
		await ProfileService.deleteAccount(userData?.id ?? 0);
		setDeleteDialogOpen(false);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
			<ProfileCard userData={userData} />

			{userData?.is_tutor && (
				<Accordion sx={{ bgcolor: '#fff', border: 'none' }}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='payment-settings-content'
						id='payment-settings-header'>
						<Typography variant='h6'>Payment Settings</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<StripeConnectAccountUpdate />
					</AccordionDetails>
				</Accordion>
			)}

			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls='delete-account-content'
					id='delete-account-header'>
					<Typography variant='h6' color='error'>
						Delete Account
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography sx={{ mb: 2 }}>
						Deleting your account is permanent and cannot be undone. All your
						data will be permanently removed.
					</Typography>
					<Button
						fullWidth
						color='error'
						sx={{
							backgroundColor: '#fff0f0',
							borderColor: '#D32F2F',
							borderWidth: 2,
							borderStyle: 'solid',
							mb: 1,
						}}
						onClick={() => setDeleteDialogOpen(true)}>
						Delete Account
					</Button>
				</AccordionDetails>
			</Accordion>

			<Dialog
				open={deleteDialogOpen}
				onClose={() => setDeleteDialogOpen(false)}>
				<DialogTitle>Confirm Account Deletion</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to delete your account? This action cannot be
						undone and you will lose:
						<ul>
							<li>All your profile information</li>
							<li>Access to your subscriptions</li>
							<li>All saved preferences and settings</li>
						</ul>
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
					<Button
						onClick={handleDeleteAccount}
						color='error'
						variant='outlined'
						sx={{
							backgroundColor: '#fff0f0',
							borderColor: '#D32F2F',
							borderWidth: 2,
							color: '#D32F2F',
						}}>
						Delete Account
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}

export default AccountTab;
