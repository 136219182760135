import React from 'react';

function PrivacyPolicy() {
	return (
		<main
			className='home-container container tp'
			style={{ maxWidth: '1000px', paddingTop: '100px' }}>
			<h1 className='c10' id='h.gjdgxs'>
				<span className='c5'>
					<br />
					Privacy Policy
				</span>
			</h1>
			<p className='c4'>
				<b className='c0'>Last Updated: November 21, 2024</b>
			</p>
			<p className='c4'>
				<span className='c0'>
					The Memoryze.co website and subdomains, mobile applications, and other
					media, including all their respective features and content
					(collectively, the "Site"), is a service provided by Memoryze
					("Memoryze," "we," or "our").
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>How This Privacy Policy Applies</span>
			</h2>

			<p className='c4'>
				<span className='c0'>
					Your privacy is extremely important to us. To protect your
					information, we have developed this Privacy Policy (“Privacy Policy”).
					It applies to all information that any visitor or user of the Site
					(referred to as "User," "you," or "your") submits to us in connection
					with their access to and/or use of the Site. This Privacy Policy
					outlines the information we collect from you through the Site, how we
					use that information, and the legal grounds for doing so.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					It also explains how we may share your information, as well as your
					rights and choices regarding the information you provide to us. Any
					capitalized terms not defined in this Privacy Policy are defined in
					our Terms of Service.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					By using the Site, you agree to the practices described in this
					Privacy Policy (including any future updates to this policy) and the
					Terms of Use, which governs this Privacy Policy and includes all
					disclaimers of warranties and limitations of liabilities.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>What Information We Collect and Receive</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					To provide you with the best experience on our Site, we need to
					collect and process certain information. Depending on how you interact
					with the Site, this may include:
				</span>
			</p>
			<ul className='c1 lst-kix_list_1-0 start'>
				<li className='c7 li-bullet-0'>
					<span className='c0'>
						<b>Information you Provide</b> <br /> When you use the Site, such as
						by registering and creating an account, completing forms, or posting
						User Content in the Interactive Areas (as defined in the Terms of
						Use), we may collect information like your first and last name,
						email address, mailing address, and phone number. We may also
						request this information if you subscribe to our newsletter,
						complete surveys, or contact us directly.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						<b>Transaction and Request Details</b>
						<br /> If you make any transactions or requests through the Site, we
						collect details about those activities. For payment processing, we
						partner with Stripe. The credit card and payment information you
						submit is processed by Stripe in accordance with their{' '}
						<a href='https://stripe.com/en-US/privacy'>Privacy Policy</a>
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						<b>Activity and Interaction Data</b>
						<br />
						We collect information about your activity on and interaction with
						Memoryze, such as your Internet Protocol (IP) address, the type of
						device or browser you use to access the Site, and usage data (e.g.,
						time spent on the Site and your actions on it).
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c14 c17'>
						<b>Web Traffic Analytics</b>
						<br />
						We collect information on how visitors interact with the Site using
						web traffic analytics. We partner with third-party services like
						Google Analytics and Mixpanel for these analytics. You can opt out
						of Google Analytics here. For more information on how we use data
						analytics to improve and secure our Site, see the section on Cookies
						and How We Utilize Them below.
					</span>
				</li>
				<li className='c12 li-bullet-0'>
					<span className='c0'>
						<b>Communications You Send</b>
						<br />
						We collect and store communications you send to us, such as support
						requests, feedback, questions, or problem reports.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					Additionally, we may collect aggregated, anonymized, or pseudonymized
					data to improve the safety and security of the Site, enhance user
					experience, and comply with legal obligations.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Please note, you can choose not to provide some or all of this
					information. However, this may limit your ability to fully use the
					Site
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>Cookies and How We Utilize Them</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					We use the following types of cookies and other technologies:
				</span>
			</p>
			<ul className='c1 lst-kix_list_2-0 start'>
				<li className='c7 li-bullet-0'>
					<span className='c0'>
						<b>Security Cookies</b>
						<br />
						We use cookies to secure the Site and facilitate features that help
						us detect and prevent activities that may violate our rules or Terms
						of Use, such as unauthorized access or suspicious activity.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						<b>Localization Cookies</b>
						<br />
						Some cookies help us tailor your experience by displaying the Site
						in your preferred language or region.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						<b>Site Features and Services Cookies</b>
						<br />
						These cookies enable additional functionality on the Site, enhancing
						the user experience and helping us deliver our products and
						services.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						<b>Performance Cookies</b>
						<br />
						Performance cookies help us manage and direct traffic across servers
						and provide insights on how the Site is performing, ensuring we can
						deliver the best possible user experience.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c14 c17'>
						<b>Analytics and Research Cookies</b>
						<br />
						Cookies and other technologies, such as Google Analytics, help us
						analyze user interactions with the Site. This information is used to
						improve our features and content. You can opt out of Google
						Analytics{' '}
						<a
							className='c16'
							href='https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1622075127596000&amp;usg=AOvVaw2N058Cf-pR-pq_XMIzr7ON'>
							here
						</a>
					</span>
					<span className='c0'>.</span>
				</li>
				<li className='c12 li-bullet-0'>
					<span className='c14 c17'>
						<b>Marketing and Online Behavioral Advertising Cookies</b>
						<br />
						We use cookies to deliver targeted advertisements on social media
						platforms based on your activity on the Site and to track the
						performance of those ads. Some of our partners may also use cookies
						to track your interactions with their services. These third-party
						cookies are subject to the privacy policies of those partners. You
						can opt out of having your online behavior tracked for advertising
						purposes through the Self-Regulatory Program for Online Behavioral
						Advertising.
					</span>
				</li>
			</ul>
			<h2 className='c4'>
				<span className='c5'>
					Limiting or Blocking Cookies and Our Do Not Track Policy
				</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Your browser allows you to manage cookie settings, including the
					option to reject cookies. However, if you block or delete cookies, you
					may not be able to use all features of the Site.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Some browsers offer "Do Not Track" features, which request websites
					not to track you. These features may not be consistent across all
					browsers. Blocking cookies may also limit some of the tracking
					activities described above.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Please note that your cookie preferences are device- and
					browser-specific.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>How We Use the Information We Collect</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					We use the information we collect for the following purposes:
				</span>
			</p>
			<ul className='c1 lst-kix_list_3-0 start'>
				<li className='c7 li-bullet-0'>
					<span className='c0'>
						To protect the Site and ensure its security
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						To provide and improve the Site and our services
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>To personalize your experience on the Site</span>
				</li>
				<li className='c12 li-bullet-0'>
					<span className='c0'>
						To track and analyze Site usage to enhance performance and user
						experience
					</span>
				</li>
			</ul>
			<h2 className='c4'>
				<span className='c5'>European Union Users</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					For users in the European Economic Area (EEA), we are required by law
					to have a "lawful basis" for collecting and processing personal data.
					Our lawful bases include:
				</span>
			</p>
			<ol className='c1 lst-kix_list_4-0 start'>
				<li className='c7 li-bullet-0'>
					<span className='c0'>
						<b>Consent:</b>
						<br />
						In certain cases, you have affirmatively provided us with your
						consent to collect your personal data for a specific purpose, such
						as providing your name and email address when you register an
						Account or sign up for our newsletter.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						<b>Contract:</b>
						<br />
						We may need your personal data to comply with our contractual
						obligation to deliver the Site, such as fulfilling subscriptions or
						transactions.
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						<b>Legal Obligations:</b>
						<br />
						Sometimes the law requires us to collect and use your data, such as
						applicable tax laws may require us to retain records of payments
						made through the Site.
					</span>
				</li>
				<li className='c12 li-bullet-0'>
					<span className='c0'>
						<b>Legitimate Interests:</b>
						<br />
						This is a technical term that means we have a good and fair reason
						to use your data and we do so in ways which do not harm your
						interests and rights, such as to pursue our legitimate interests in
						a way that might reasonably be expected as part of running our
						business and that does not materially impact your rights, freedom,
						or interests. For example, we use identity, device, and location
						information to prevent fraud and abuse and to keep the Site secure,
						may also send you promotional communications about the Site and
						services subject to your right to opt out, analyze how users
						interact with the Site so we can understand better what elements do
						or do not work well, which allows us to improve and develop the
						quality of the online experience we offer all our Users.
					</span>
				</li>
			</ol>
			<h2 className='c2'>
				<span className='c5'>How We Share the Information We Collect</span>
			</h2>
			<p className='c2'>
				<b className='c10'>Information that May be Publicly Accessible</b>
			</p>
			<p className='c4'>
				<span className='c0'>
					If you post User Content in Interactive Areas on the Site, other Users
					will be able to view it along with your name and email address (if
					provided when posting). Please be mindful of what you share, as this
					information may be publicly visible.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Additionally, personal information in your Account profile is shared
					or visible to other Users unless you select the option to keep your
					profile anonymous. Keep in mind that if you choose to remain
					anonymous, some Site features may not be available to you.
				</span>
			</p>
			<p className='c2'>
				<b className='c10'>Information We Never Share Publicly</b>
			</p>
			<p className='c4'>
				<span className='c0'>
					The following personal information is never publicly displayed or
					shared with other Users, unless you explicitly share it in the
					Interactive Areas or your public Account profile:
				</span>
			</p>
			<ul className='c1 lst-kix_list_5-0 start'>
				<li className='c7 li-bullet-0'>
					<span className='c0'>Your name</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						Your registration date for services (e.g., newsletter/mailing list)
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						Your email address, IP address, mailing address, or phone number
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						Any identity verification information or documentation
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>Payment details</span>
				</li>
				<li className='c12 li-bullet-0'>
					<span className='c0'>Communications you send to us.</span>
				</li>
			</ul>
			<p className='c2'>
				<b className='c10'>
					Information We Share with Trusted Third-Party Service Providers
				</b>
			</p>
			<p className='c4'>
				<span className='c0'>
					We may share certain information with trusted third-party service
					providers to help us operate, improve, and secure the Site. This may
					include services for processing payments or managing advertisements.
					These third-party providers are required to use your information only
					for the purposes we authorize and to protect your personal information
					with the same level of care we do. In some cases, we may share
					aggregated or anonymized data that does not directly identify you.
				</span>
			</p>
			<p className='c2'>
				<b className='c10'>
					Information We Share to Protect Memoryze and Comply with the Law
				</b>
			</p>
			<p className='c4'>
				<span className='c0'>
					We may disclose your information when necessary to comply with the
					law, protect against fraud or abuse, or protect the rights, property,
					or safety of Memoryze, its Users, or others. This includes situations
					where:
				</span>
			</p>
			<ul className='c1 lst-kix_list_6-0 start'>
				<li className='c7 li-bullet-0'>
					<span className='c0'>
						You are violating the Terms of Use or Privacy Policy
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						You are interfering with the rights or property of Memoryze or a
						third party
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						You are breaking applicable laws or regulations
					</span>
				</li>
				<li className='c9 li-bullet-0'>
					<span className='c0'>
						It is required by law or requested by government authorities
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					In the event of a merger, acquisition, or sale of Memoryze or its
					assets, we may transfer or assign your information to the new entity.
					If Memoryze faces bankruptcy or insolvency proceedings, your personal
					information may be transferred as part of that process.
				</span>
			</p>
			<h3 className='c10'>
				<span className='c14'>Links to Other Websites and Services</span>
			</h3>
			<p className='c4'>
				<span className='c0'>
					The Site may include links to third-party websites or services, which
					may have different privacy practices. When you click on these links,
					their privacy policies govern how your data is handled.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>Data Retention</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					We will retain your information as long as necessary to provide the
					Site or our services and to comply with legal obligations, resolve
					disputes, or enforce agreements. We may retain anonymized or
					aggregated data indefinitely.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>Cross-Border Data Transfers</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					As Memoryze is based in the United States, your information will be
					collected and processed under U.S. law, which may differ from the data
					protection laws in your country. If you are located outside of the
					U.S., be aware that U.S. laws may provide different levels of
					protection for your personal information.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>Children’s Privacy</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					We prioritize the privacy of children and are committed to complying
					with the Children’s Online Privacy Protection Act (COPPA). This Site
					does not knowingly collect, use, or disclose personal information from
					children under the age of 13. If we discover that we have
					unintentionally collected personal information from a child under 13,
					we will promptly delete it. If you are a parent or guardian and
					believe we have collected information from your child under 13, please
					contact us at <a href='mailto:hello@memoryze.co'>hello@memoryze.co</a>
					, and we will take immediate steps to delete the information.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>Accessing Your Information</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Users in certain countries, including those in the European Economic
					Area, have specific rights regarding their personal information. These
					rights include the ability to access, correct, or request deletion of
					your personal information. However, even after a deletion request, we
					may retain some data if required by law or for legitimate business
					purposes.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>Email Notifications</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					We will only send you email communications that you have agreed to
					receive. You will receive transactional emails related to your
					interactions with us. You can also opt-in to marketing emails and can
					change your preferences or unsubscribe at any time by adjusting your
					notification settings. We will send service-related announcements as
					necessary.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					We adhere to a strict "no-spam" policy. We will clearly identify
					emails as advertisements, include our business address, and monitor
					third-party marketing services for compliance. If you wish to
					unsubscribe from marketing emails, you can do so using the link at the
					bottom of each email. If you experience difficulty unsubscribing,
					please contact us at hello@Memoryze.co, with "Unsubscribe" in the
					subject line, and we will remove you within 7 business days. Note:
					unsubscribe requests should not be made by replying directly to
					newsletters.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>Security</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					We take the security of your personal data seriously. We use
					industry-standard practices, such as Secure Sockets Layer (SSL)
					encryption, to protect your data during transmission over the
					Internet. While we strive to protect your data, please be aware that
					no method of transmission or electronic storage is completely secure,
					and we cannot guarantee absolute security.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>Updates to This Privacy Policy</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					We may update this Privacy Policy at any time. Any material changes
					will be posted on the Site or communicated via email. The effective
					date of the most recent version will be noted at the top of this page.
					Updates to this policy will not apply retroactively.
				</span>
			</p>
			<h2 className='c4'>
				<span className='c5'>Contact</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					If you have questions or comments regarding this Privacy Policy,
					please reach out to us at <a href="mailto:hello@Memoryze.co">hello@Memoryze.co</a>.
				</span>
			</p>
			<p className='c18'>
				<span className='c0'></span>
			</p>
		</main>
	);
}

export default PrivacyPolicy;
