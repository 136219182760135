import React from 'react';
import { Box, Typography, Container, Stack, Button } from '@mui/material';
import { axiosForMedia } from '../../api/axios';
import { USERS_URL } from '../../api/constants';

const UnsubscribeFromEmails: React.FC = () => {
	// App store URLs - replace with actual URLs
	const APP_STORE_URL = 'https://apps.apple.com/app/memoryze/id123456789';
	const PLAY_STORE_URL =
		'https://play.google.com/store/apps/details?id=com.app.memoryze';

	

	return (
		<Container maxWidth='sm'>
			<Box
				sx={{
					minHeight: '100vh',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					py: 4,
					pt: 18,
					textAlign: 'center',
				}}>
				

				<Typography variant='h4' component='h1' gutterBottom>
					Manage Email Preferences
				</Typography>

				<Typography variant='body1' sx={{ mb: 4 }}>
					To manage your email preferences and notification settings, please
					open the Memoryze app. If you haven't installed our app yet, you can
					download it from your preferred app store.
				</Typography>

				<Stack
					spacing={2}
					direction='row'
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<a href={APP_STORE_URL} target='_blank' rel='noopener noreferrer'>
						<img
							src='https://non-environment-specific-bucket.s3.us-east-1.amazonaws.com/download-on-apple.png'
							alt='Download on the App Store'
							style={{ width: 120, height: 35 }}
						/>
					</a>

					<a href={PLAY_STORE_URL} target='_blank' rel='noopener noreferrer'>
						<img
							src='https://non-environment-specific-bucket.s3.us-east-1.amazonaws.com/get-on-googgle-play.png'
							alt='Get it on Google Play'
							style={{ width: 120, height: 35 }}
						/>
					</a>
				</Stack>

				<Typography variant='body2' sx={{ mt: 4, color: 'text.secondary' }}>
					Thank you for using Memoryze. We're committed to providing you with
					the best possible experience.
				</Typography>
			</Box>
		</Container>
	);
};

export default UnsubscribeFromEmails;
