export const featuresData = [
	{
		name: 'Endless Learning Possibilities',
		description:
			'From mastering new languages to discovering psychology hacks, our audio library has something for everyone. The possibilities are limitless—just press play.',
		image: '/images/vectors/landingPage/Topics.png', // Direct path
	},
	{
		name: 'Challenge Your Knowledge',
		description:
			'Put your understanding to the test with interactive quizzes after each lesson. Coming soon to make your learning experience even more engaging.',
		image: '/images/vectors/landingPage/Quizzes.png', // Direct path
	},
	{
		name: 'Learn from the Best',
		description:
			'Our courses are crafted by top educators from around the world, ensuring you get high-quality learning content tailored to your needs.',
		image: '/images/vectors/landingPage/Educators.png', // Direct path
	},
	{
		name: 'Connect and Grow Together',
		description:
			'Join a thriving community of learners. Share insights, exchange ideas, and grow your knowledge alongside others with similar interests.',
		image: '/images/vectors/landingPage/Community.png', // Direct path
	},
	{
		name: 'Learn on Your Own Terms',
		description:
			'Download lessons to enjoy uninterrupted learning anytime, anywhere—even without an internet connection.',
		image: '/images/vectors/landingPage/OfflineUse.png', // Direct path
	},
	{
		name: 'Supportive Learning Materials',
		description:
			'Enhance your learning with concise resources that accompany each course, giving you the tools you need to succeed.',
		image: '/images/vectors/landingPage/Resources.png', // Direct path
	},
];

export const memojies = [
	{
		image: '/images/vectors/landingPage/memojies/redHairGirl.png', // Direct path
		name: 'Treasure',
		comment:
			"When I learned about Memoryze, I was excited. Don't get me wrong books are amazing but scouring through law books can be a dreadful task and I love processes that make life easier for me. With Memoryze, I would be able to listen to lessons while multitasking other activities. What a fun practicable way to learn!",
	},
	{
		image: '/images/vectors/landingPage/memojies/dreadGuy.png', // Direct path
		name: 'Pedro',
		comment:
			'Every morning, I spend 3 hours on the road to get to work every night, I spend another 3 hours to get home, courtesy of Lagos traffic. Using those 6 hours to learn something new would make my daily commute much more better. All I have to do is open the app, connect my Airpods and listen.',
	},
	{
		image: '/images/vectors/landingPage/memojies/beanieGirl.png', // Direct path
		name: 'Mariam',
		comment:
			"Repetition is needed to remember what you've learnt. As a student with plethora of courses, studying hasn't been easy and I didn't have a solution to this problem of memorizing until i heard about Memoryze. Memoryze is exactly what we need as students to make memorizing easy.",
	},
	{
		image: '/images/vectors/landingPage/memojies/fadeCutBoy.png', // Direct path
		name: 'Dumebi',
		comment:
			'The main thing about Memoryze that captured my attention was the inclusion of audio to my study. I think "audio learning" is a very viable yet untapped method of education. It removes the bore of long study hours and enhances retention. Kinda similar to how we remember the lyrics to our fav songs because we\'ve listened to them over and over again.',
	},
	{
		image: '/images/vectors/landingPage/memojies/beanieBoy.png', // Direct path
		name: 'Bisuga',
		comment:
			"Memoryze is probably the best educational app I've come across (and I've seen quite a lot). You mean I can learn without needing to sit and stare at a book for hours? Also, it's easy to use and accessible whenever need be. I'm a big big fan.",
	},
	{
		image: '/images/vectors/landingPage/memojies/multicoloredHairGirl.png', // Direct path
		name: 'Amy',
		comment:
			"I've always wanted to learn another language but my excuse has always been lack of time. In my defence, i run my own small bakery where I'm both baker and manager. I listen to music a lot while working so I figure occasionally swapping music for a course that helps me achieve my learning goals is a good move.",
	},
];
