import React, { useEffect, useState, useRef } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
const IconWithText = '/images/LogoWithText.png';
import './Header.css';
import { getTokensExpirationStatus } from '../../../store/asyncStorage';
import { signOutFunction } from '../../../api/utils';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import SchoolIcon from '@mui/icons-material/School';
import HelpIcon from '@mui/icons-material/Help';
import LoginIcon from '@mui/icons-material/Login';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux';
import { IStore, IUser } from '../../../types/interfaces';

interface ILink {
	url: string;
	text: string;
	show: boolean;
	icon: React.ReactNode;
}

interface StateProps {
	userData: IUser | null;
}

function Header({ userData }: StateProps) {
	const sidebarRef = useRef<HTMLDivElement | null>(null); // Ref for sidebar element
	const closeButtonRef = useRef<HTMLDivElement | null>(null); // Ref for the close button
	const navigate = useNavigate();
	const [enableSidebar, setEnableSidebar] = useState(false);
	const [isTransitioning, setIsTransitioning] = useState(false);

	const links: ILink[] = [
		{
			url: '/for-educators',
			text: 'For Educators',
			show: true,
			icon: <SchoolIcon />,
		},
		{ url: '/faqs', text: 'FAQs', show: true, icon: <HelpIcon /> },
		{
			url: '/app/login',
			text: 'Login',
			show: !getTokensExpirationStatus()?.isUserLoggedIn,
			icon: <LoginIcon />,
		},
		{
			url: '/app/search',
			text: 'Search',
			show: !!getTokensExpirationStatus()?.isUserLoggedIn,
			icon: <SearchIcon />,
		},
		{
			url: '/app/settings',
			text: 'Settings',
			show: !!getTokensExpirationStatus()?.isUserLoggedIn,
			icon: <SettingsIcon />,
		},
	];

	const toggleSidebar = () => {
		if (enableSidebar) {
			// Start closing transition
			setIsTransitioning(true);
			setTimeout(() => {
				setEnableSidebar(false);
				setIsTransitioning(false); // Reset transitioning state after closing
			}, 50); // Match this duration with your CSS transition duration
		} else {
			// Start opening transition
			setEnableSidebar(true);
			setIsTransitioning(true); // Mark as transitioning when opening
			setTimeout(() => {
				setIsTransitioning(false); // Reset transitioning state after opening
			}, 50); // Match this duration with your CSS transition duration
		}
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (
			sidebarRef.current &&
			!sidebarRef.current.contains(event.target as Node) &&
			closeButtonRef.current &&
			!closeButtonRef.current.contains(event.target as Node)
		) {
			setEnableSidebar(false); // Close sidebar if click is outside
		}
	};

	useEffect(() => {
		// Add click event listener to the document
		document.addEventListener('mousedown', handleClickOutside);

		// Cleanup the event listener on component unmount
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);
	const onLogOut = () => {
		setEnableSidebar(false);
		signOutFunction();
		navigate('/');
	};

	return (
		<nav
			className='navigation'
			role='navigation'
			aria-label='Main navigation'
			style={{ display: 'flex', justifyContent: 'space-between' }}>
			<Navbar.Brand href='/' className='logo'>
				<img
					src={IconWithText}
					alt='Classroom Clone Logo'
					className='logo-text-image'
				/>
			</Navbar.Brand>
			<Nav className='mr-auto header-links' role='menubar'>
				{links.map(({ url, text, show }, i) => {
					return show ? (
						<a
							href={url}
							key={i + 'link'}
							role='menuitem'
							aria-current={
								url === window.location.pathname ? 'page' : undefined
							}>
							{text}
						</a>
					) : null;
				})}
				{getTokensExpirationStatus()?.isUserLoggedIn && (
					<a
						href='#'
						onClick={onLogOut}
						role='menuitem'
						aria-label='Log out of your account'>
						Log Out{' '}
						<LogoutIcon sx={{ fontSize: 18, mr: '5px' }} aria-hidden='true' />
					</a>
				)}
			</Nav>
			{userData && (
				<Box
					component='a'
					href='/app/settings'
					className='profile-avatar'
					role='link'
					aria-label='Go to settings'>
					<Avatar
						src={
							userData?.profile_image ? userData?.profile_image : userData?.is_tutor
								? '/images/defaultEducatorProfileImage.png'
								: '/images/defaultLearnerProfileImage.png'
						}
						sx={{
							width: 32,
							height: 32,
							cursor: 'pointer',
							bgcolor: '#2F69F8',
						}}
					/>
				</Box>
			)}
			<div
				className={enableSidebar ? 'closeBtn' : 'bars'}
				onClick={toggleSidebar}
				ref={closeButtonRef}
				role='button'
				aria-expanded={enableSidebar}
				aria-controls='mySidenav'
				aria-label={
					enableSidebar ? 'Close navigation menu' : 'Open navigation menu'
				}
				tabIndex={0}
				onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						toggleSidebar();
					}
				}}>
				{enableSidebar ? <>&times;</> : <>&#9776;</>}
			</div>
			{(enableSidebar || isTransitioning) && (
				<div
					id='mySidenav'
					className={`sidenav ${enableSidebar ? 'open' : ''} ${
						isTransitioning ? 'transitioning' : ''
					}`}
					ref={sidebarRef}
					role='menu'
					aria-label='Mobile navigation menu'>
					{links.map(({ url, text, show, icon }, i) => {
						return show ? (
							<a
								href={url}
								className='side-link'
								onClick={toggleSidebar}
								key={i}
								role='menuitem'
								aria-current={
									url === window.location.pathname ? 'page' : undefined
								}>
								<span
									className='side-link-icon'
									style={{ marginRight: '5px' }}
									aria-hidden='true'>
									{icon}
								</span>
								{text}
							</a>
						) : null;
					})}
					{getTokensExpirationStatus()?.isUserLoggedIn && (
						<a
							href='#'
							className='side-link'
							onClick={onLogOut}
							role='menuitem'
							aria-label='Log out of your account'>
							<span
								className='side-link-icon'
								style={{ marginRight: '5px' }}
								aria-hidden='true'>
								<LogoutIcon />
							</span>
							Log Out
						</a>
					)}
				</div>
			)}
		</nav>
	);
}

const mapStateToProps = (state: IStore): StateProps => ({
	userData: state.userData,
});

export default connect(mapStateToProps)(Header);
