import {
	AUDIO_URL,
	USERS_URL,
	SIGN_UP_URL,
	LOGIN_URL,
	GET_ALL_COURSES_URL,
	FORGOT_PASSWORD_URL,
	GET_COURSES_BY_CATEGORY_URL,
	SOCIAL_AUTH_URL,
} from './constants';
import { store } from '../store/store';
import {
	performSignOut,
	setCourseCategory,
	setUserData,
	updateCourseCategoryByName,
} from '../store/actions';
import { axiosDefault, axiosWithNoAuth } from './axios';
import { defaultTopAvailableCategories } from '../store/data';
import { showNetworkErrorMessageIfNetworkError } from './axios';
import { ICourse, ToasterType, IUser } from '../types/interfaces';
import { ToasterService } from '../MainUtils/ToasterService';
import { ToasterDuration } from '../types/constants';
import { AxiosError } from 'axios';

/* *********************** API REQUESTS ********** */

interface ILoginRequestBody {
	email: string;
	password: string;
}
interface ILoginResponse {
	access: string;
	refresh: string;
	user: IUser;
	code: string;
	is_verified: boolean;
}
export const loginAndSetUserData = (
	body: ILoginRequestBody
): Promise<ILoginResponse> => {
	return axiosWithNoAuth.post(LOGIN_URL, body).then((res) => {
		store.dispatch(setUserData(res?.data?.user));
		return res?.data;
	});
};

export const signupRequest = (body: any) => {
	return axiosWithNoAuth.post(SIGN_UP_URL, body).then((res) => res?.data);
};

export async function getAndSetCourseCategories(interests: string[]) {
	if (interests.length < 5) {
		for (let i = 0; interests.length <= 5; i++) {
			if (!interests.includes(defaultTopAvailableCategories[i])) {
				interests.push(defaultTopAvailableCategories[i]);
			}
		}
	}

	const coursesRes = await axiosDefault.post(GET_COURSES_BY_CATEGORY_URL, {
		categories: interests,
	});

	const categoriesWithCourses = coursesRes?.data.categories || [];
	categoriesWithCourses.forEach((categoryWithCourses: any) => {
		store.dispatch(setCourseCategory(categoryWithCourses));
	});
}

export async function loadAndUpdateCourseCategoryList(
	page: number,
	category: string
) {
	const res = await axiosDefault(
		`${GET_ALL_COURSES_URL}?make_public=true&size=${page}&search=${category}&search_only=categories&ordering=-created_at`
	);
	const data = res?.data || null;
	const coursesList = data?.results || [];
	store.dispatch(updateCourseCategoryByName(category, coursesList));
}

export const updateUserData = async (user: Partial<IUser>) => {
	try {
		const { userData } = store.getState();
		const res = await axiosDefault.patch(USERS_URL + userData?.id, user);
		store.dispatch(setUserData(res?.data));
	} catch (error: any) {
		apiErrorHandler(error);
	}
};

export function getFileExtensionFromURL(url: string) {
	const parts = url.split('/');
	const fileName = parts[parts.length - 1];
	const lastDotIndex = fileName.lastIndexOf('.');
	if (lastDotIndex !== -1) {
		return fileName.substring(lastDotIndex + 1);
	}
	return null; // If no file extension is found
}

export const fetchCourseById = async (courseId: number): Promise<ICourse> => {
	const res = await axiosDefault(GET_ALL_COURSES_URL + courseId);
	return res?.data;
};

export const fetchLessonById = async (lessonId: number) => {
	const res = await axiosDefault(AUDIO_URL + lessonId);
	return res;
};

//general api error handlerFunction
export const apiErrorHandler = (error: AxiosError | ErrorEvent) => {
	showNetworkErrorMessageIfNetworkError(error);
	ToasterService.dispatchAddToaster({
		message: 'Sorry an error occurred!',
		duration: ToasterDuration.SHORT,
		type: ToasterType.ERROR,
	});
};

export const requestNewPassword = (body: any) => {
	return axiosWithNoAuth
		.put(FORGOT_PASSWORD_URL, body)
		.then((res) => res?.data);
};

export const signOutFunction = () => {
	store.dispatch(performSignOut());
};
/**
 * Authenticates a user using social login providers
 * @param provider - The social authentication provider ("google-oauth2", "apple-id", or "linkedin-oauth2")
 * @param code - The authentication code from the provider
 * @returns Promise resolving to an object containing authentication tokens and user data, or undefined if authentication fails
 */
export const socialSignIn = async (
	provider: 'google-oauth2' | 'apple-id' | 'linkedin-oauth2',
	token: string,
	requestType: 'login' | 'signup'
): Promise<
	| {
			access: string;
			refresh: string;
			user: IUser;
	  }
	| {
			error: string;
	  }
	| undefined
> => {
	try {
		const payload: {
			provider: string;
			access_token?: string;
			request_type: string;
	} = {
		provider,
		request_type: requestType,
		access_token: token,
	};

		const response = await axiosWithNoAuth.post(SOCIAL_AUTH_URL, payload);
		return response?.data;
	} catch (error: any) {
		return error.response.data;
	}
};

const APP_STORE_URL = 'https://apps.apple.com/app/your-app-id'; // Replace with your App Store URL
const PLAY_STORE_URL =
	'https://play.google.com/store/apps/details?id=your.package.id'; // Replace with your Play Store URL

export const openMobileAppFeature = (path: string): void => {
	try {
		const url = `memoryze://app/${path}`;

		// Detect OS
		const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
		const isAndroid = /Android/.test(navigator.userAgent);

		// Create a hidden iframe to attempt opening the app
		const iframe = document.createElement('iframe');
		iframe.style.display = 'none';
		document.body.appendChild(iframe);

		// Set timeout to redirect to store if app isn't opened
		setTimeout(() => {
			if (isIOS) {
				window.location.href = APP_STORE_URL;
			} else if (isAndroid) {
				window.location.href = PLAY_STORE_URL;
			} else {
				// Optional: handle desktop or unsupported devices
				window.location.href = APP_STORE_URL; // or your website
			}
		}, 2500); // Wait 2.5 seconds before redirecting

		// Attempt to open the app
		iframe.src = url;
	} catch (error) {
		if (error instanceof Error) {
			ToasterService.dispatchAddToaster({
				message: 'Sorry an error occurred!',
				duration: ToasterDuration.SHORT,
				type: ToasterType.ERROR,
			});
		}
	}
};
